import { defineStore } from "pinia";
import type { SeoTags } from "~/interfaces/seo";
import { type NuxtPage} from "nuxt/schema";
export const SEO_STORE_NAME = "seo-tags";

type State = {
    _seoTags?: SeoTags,
    _route?: NuxtPage
}


export const useSeoTagsStore = defineStore(SEO_STORE_NAME, {
    state:  () : State => ({
        _seoTags: undefined,
        _route: undefined
    }),
    actions: {
        setSeoTags(value: SeoTags) {
            this._seoTags = value;
        },
        setRoute(value: NuxtPage) {
            this._route = value;
        }
    },
    getters: {
        seoTags: (state) =>  state._seoTags,
        route: (state) => state._route
    },
});